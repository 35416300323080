import React, { useState } from "react";

import {Grid, Button, FormHelperText, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

import { observer } from "mobx-react";
import { useFormik } from 'formik';

import { useAppContext } from "../../../../AppContext";
import { authStyles } from '../../../../styles/authStyles';
import PasswordTextField from "../../../../components/common/PasswordTextField";
import { ResetPasswordSchema } from './schemas/ResetPasswordSchema';
import { useTranslation } from "react-i18next";

export const ResetPasswordForm = observer(() => {
  const { t } = useTranslation();
  const { classes } = authStyles();
  const { api, store } = useAppContext();
  const [ passwordChanged, setPasswordChanged ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      pwd: '',
      confirmPwd: '',
    },
    validationSchema: ResetPasswordSchema(t),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const params = new URLSearchParams(location.search);
        const token = params.has("token") ? params.get("token") : '';
        const result = await api.authApi.resetPassword({
          pwd: values.pwd,
          token: token || '',
        });
        if (result.success) {
          store.authStore.clearVerificationToken();
          setPasswordChanged(true);
        }
      }
      finally {
        setLoading(false);
      }
    }
  });

  return <>
    <Typography variant="h4" className={classes.header}>{t('recoverPasswordTitle')}</Typography>
    {!passwordChanged && <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <FormHelperText error style={{marginTop: '37px'}} className="yna">{formik.errors.pwd ?? ''}</FormHelperText>
          <PasswordTextField
            disabled={loading}
            name="pwd" className="yna" autoComplete="off" label="Новый пароль" variant="outlined"
            error={formik.errors.pwd !== undefined}
            value={formik.values.pwd} fullWidth
            onChange={formik.handleChange} />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText style={{marginTop: '37px'}} error className="yna">{formik.errors.confirmPwd ?? ''}</FormHelperText>
          <PasswordTextField
              disabled={loading}
              name="confirmPwd" className="yna" autoComplete="off" label="Пароль еще раз" variant="outlined"
              error={formik.errors.confirmPwd !== undefined}
              value={formik.values.confirmPwd} fullWidth
              onChange={formik.handleChange} />
        </Grid>
        <Grid item xs={12} className={classes.submitWrapper}>
          <Button disabled={loading} className="yna" type="submit" variant="contained" color="primary" fullWidth>
            Изменить пароль
          </Button>
        </Grid>
      </Grid>
    </form>}
    {passwordChanged && <Grid container>
      <Grid item xs={12}>
        Пароль был изменен.
      </Grid>
      <Grid item xs={12} className={classes.submitWrapper}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button className="yna" type="button" variant="contained" color="primary" fullWidth>
            OK
          </Button>
        </Link>
      </Grid>
    </Grid>}
  </>
});
