import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const initI18next = () => {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: {
            registration: 'Registration',
            createAccountVia: 'Create account via',
            loginVia: 'Login via',
            nameInput: 'Name Surname',
            emailInput: 'Email',
            passwordInput: 'Password',
            currentPasswordInput: 'Current password',
            newPasswordInput: 'New password',
            privacyPolicyUrl: 'https://legal.yna.run/privacy-policy/',
            privacyPolicyLabel: 'Privacy policy',
            terms: 'https://legal.yna.run/terms-of-use/',
            termsLabel: 'Terms of use of the site',
            agree: 'Agree with the',
            and: 'and',
            or: 'or',
            createAccountButton: 'Create Account',
            alreadyHaveAccount: 'Already have an account?',
            alreadyHaveLogin: 'Login',
            authorization: 'Authorization',
            rememberMe: 'Remember me',
            login: 'Login',
            errorTitle: 'Something went wrong',
            networkError: 'Error connecting to the server, try again or come back later',
            close: 'Close',
            notRegistered: 'Not registered yet?',
            notRegisteredCreateAccount: 'Create Account',
            forgotPassword: 'Forgot password?',
            changePasswordTitle: 'Change password',
            changePassword: 'Change password',
            recoverPasswordTitle: 'Recover password',
            recoverPassword: 'Recover password',
            emailSent: 'An email with a link for password recovery has been sent to your email address.',
            noCorrectEmail: 'Incorrect email address',
            emailRequired: 'Email is required',
            passwordRequired: 'Password is required',
            passwordsCompability: 'Passwords do not match',
            passwordsCompabilityRequired: 'Password confirmation is required',
            verifyCodeTitle: 'Enter the 4-digit code',
            verifyCodeSubtitle: 'A 4-digit code has been sent to the email address you specified in the previous step.',
            verifyCodeBtn: 'Confirm code',
            retryCodeLinkTitle: 'Request code again',
            verifyCodeExpired: 'The code has expired',
            userAlreadyExists: 'User already exists',
          }
        },
        ru: {
          translation: {
            registration: 'Регистрация',
            createAccountVia: 'Создайте аккаунт с помощью',
            loginVia: 'Войдите с помощью',
            currentPasswordInput: 'Текущий пароль',
            newPasswordInput: 'Новый пароль',
            nameInput: 'Имя Фамилия',
            emailInput: 'Email',
            passwordInput: 'Пароль',
            privacyPolicyUrl: 'https://legal.yna.run/privacy-policy-ru/',
            privacyPolicyLabel: 'Политикой конфиденциальности',
            terms: 'https://legal.yna.run/terms-of-use-ru/',
            termsLabel: 'Правилами использования сайта',
            agree: 'Соглашаюсь с',
            and: 'и',
            or: 'или',
            createAccountButton: 'Создать аккаунт',
            alreadyHaveAccount: 'Уже есть аккаунт?',
            alreadyHaveLogin: 'Авторизуйтесь',
            authorization: 'Авторизация',
            rememberMe: 'Запомнить меня',
            login: 'Вход',
            errorTitle: 'Что-то пошло не так',
            networkError: 'Ошибка в соединении с сервером, попробуйте еще раз или зайдите позже ',
            close: 'Закрыть',
            notRegistered: 'Еще не зарегистрированы?',
            notRegisteredCreateAccount: 'Создайте Аккаунт',
            forgotPassword: 'Забыли пароль?',
            changePasswordTitle: 'Изменение пароля',
            changePassword: 'Изменить пароля',
            recoverPasswordTitle: 'Восстановление пароля',
            recoverPassword: 'Восстановить пароль',
            emailSent: 'На указанный адрес электронной почты было отправлено письмо с сылокой для восстановления пароля.',
            noCorrectEmail: 'Неверный адрес электронной почты',
            emailRequired: 'Поле Email обязатльно для заполнения',
            passwordRequired: 'Пароль обязателен для заполнения',
            passwordsCompability: 'Пароли не совпадают',
            passwordsCompabilityRequired: 'Подтверждение пароля обязателено для заполнения',
            verifyCodeTitle: 'Введите 4-х значный код',
            verifyCodeSubtitle: 'На Email, указанный на предыдущем шаге, отправлен 4-значный код',
            verifyCodeBtn: 'Подтвердить код',
            retryCodeLinkTitle: 'Запросить код повторно',
            verifyCodeExpired: 'Срок действия кода истек',
            userAlreadyExists: 'Пользователь уже существует',
          }
        }
      },
      lng: "ru",
      fallbackLng: "en",

      interpolation: {
        escapeValue: false
      }
    });
}
