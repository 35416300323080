import React, {ReactNode} from 'react';
import {useAppContext} from "../../AppContext";
import {useLocation, useNavigate} from "react-router-dom";

interface ExternalLoginButtonProps {
  provider : string;
  children: ReactNode;
}

const ExternalLoginButton = ({ provider, children } : ExternalLoginButtonProps) => {
    const { api, store } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const redirect = params.has("redirect") ? params.get("redirect") : null;

    const onClickHandler = async () : Promise<void> => {
      try {
        const result = await api.oauthApi.getOAuthCode(provider, redirect);
        if (!result?.result) {
          console.error(result.error);
          return;
        }
        
        if (result.verificationToken) {
            navigate("/login/verify-code");
            return;
        }

        if (result.needEmail) {
          store.authStore.setIdentifier(result.identifier);
          store.authStore.setLoginProvider(result.provider);
          navigate(redirect ? `/login/link-email?redirect=${redirect}` : '/login/link-email');
          return;
        }

        navigate(redirect || "/dashboard", { replace: true });
      }
      catch(e) {
        console.error(e);
      }
    };
    
    return <div style={{ cursor: 'pointer' }} onClick={onClickHandler}>{children}</div>
}

export default ExternalLoginButton;