import {
  Box,
  Button,
  FormControl,
  Modal,
  Typography,
  Checkbox,
} from "@mui/material";
import styled from '@emotion/styled';
import { useAppContext } from "../../../AppContext";
import { useCallback } from "react";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { WorkoutType } from "../types";
import { ReactComponent as TrophyIcon } from "../../../assets/icons/trophy.svg";
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Input } from '../../../components/Input/Input'
import { ReactComponent as RunningIcon } from "../../../assets/icons/running.svg";
import { ReactComponent as VeloIcon } from "../../../assets/icons/velo.svg";
import { ReactComponent as SwimIcon } from "../../../assets/icons/swim.svg";
import { ReactComponent as FitnessIcon } from "../../../assets/icons/fitness.svg";

import TrainingData from "./details/TrainingData";
import { getDateWithoutTz } from "../calendar/utils";
import { endOfMonth, startOfMonth } from "date-fns";

export default () => {
  const { store, api } = useAppContext();

  function updateProperty(key: string, value: any) {
    //@ts-ignore
    store.workoutStore.setWorkoutData(key, value);
  }

  function onChange(event: any) {
    if (event.target) {
      updateProperty(event.target.name, event.target.value);
    }
  }

  function onBoolChange(event: any) {
    updateProperty(event.target.name, event.target.checked);
  }

  const closeHandle = useCallback(async () => {
    store.coachStore.setWorkoutModalOpen(false);
    const _startDate = store.workoutStore.dateStart || startOfMonth(new Date());
    const _endDate = store.workoutStore.dateEnd || endOfMonth(new Date());
    const startDate = dayjs(startOfMonth(_startDate))
      .day(0)
      .add(-1, "month")
      .toDate();
    const endDate = dayjs(_endDate).add(1, "month").toDate();
    await api.workoutApi.loadMyWorkouts(startDate, endDate);
  }, [api.workoutApi, store.coachStore, store.workoutStore]);

  const save = useCallback(async () => {
    const workoutContentData = store.workoutStore.workoutContentData as any;
    store.workoutStore.workoutContentData = {
      ...workoutContentData,
      time: workoutContentData.time ? workoutContentData.time : undefined,
      pace: workoutContentData.pace ? workoutContentData.pace : undefined,
    };
    const factWorkoutContentData = store.workoutStore
      .factWorkoutContentData as any;
    store.workoutStore.factWorkoutContentData = {
      ...factWorkoutContentData,
      time: factWorkoutContentData.time
        ? factWorkoutContentData.time
        : undefined,
      pace: factWorkoutContentData.pace
        ? factWorkoutContentData.pace
        : undefined,
    };

    const workout = {
      id: store.workoutStore.workoutData.id,
      athleteId: store.coachStore.currentAthleteId || "",
      name: store.workoutStore.workoutData.name,
      startDate: getDateWithoutTz(
        store.workoutStore.workoutData.startDate || new Date()
      ),
      type:
        store.workoutStore.workoutData.type || WorkoutType[WorkoutType.running],
      description: store.workoutStore.workoutData.description,
      target: store.workoutStore.workoutData.target,
      plan: JSON.stringify(toJS(store.workoutStore.workoutContentData)),
      fact: JSON.stringify(toJS(store.workoutStore.factWorkoutContentData)),
      createdEmpty: store.workoutStore.workoutData.createdEmpty,
    };
    store.workoutStore.addWorkoutItem(workout);
    await api.workoutApi.saveWorkout(workout);
    closeHandle();
  }, [closeHandle, api.workoutApi, store.workoutStore, store.coachStore]);

  const { workoutData } = store.workoutStore;

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={store.coachStore.displayState.addWorkoutModalOpen}
      onClose={closeHandle}
    >
      <Box
        sx={{
          width: 628,
          backgroundColor: "#FFF",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            height: 56,
            padding: "0 24px",
            boxSizing: "border-box",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #DBE4F0",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: 18,
              color: "#252B4B",
              fontWeight: 600,
            }}
          >
            Редактировать тренировку
          </Typography>
          <SCloseIcon onClick={closeHandle}>
            <CloseIcon />
          </SCloseIcon>
        </Box>
        <Box
          sx={{
            boxSizing: "border-box",
            padding: "32px 24px",
            borderBottom: "1px solid #DBE4F0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl>
              <div
                style={{
                  display: "flex",
                  height: "36px",
                  flex: 1,
                }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  variant="standard"
                  style={{
                    padding: "0 16px 0 0",
                  }}
                >
                  <Input
                    inputType="text"
                    name="name"
                    fullWidth
                    value={workoutData.name}
                    onChange={onChange}
                    title="Название"
                    placeholder="Название"
                    style={{
                      backgroundColor: "#EEF5FB",
                    }}
                  />
                </FormControl>

                <Box
                  sx={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "#EEF5FB",
                    borderRadius: 4,
                  }}
                >
                  <SCheckbox>
                    <Checkbox
                      name="target"
                      value={workoutData.target}
                      checked={workoutData.target}
                      onChange={onBoolChange}
                      style={{
                        width: "36px",
                        height: "36px",
                        backgroundColor: "#EEF5FB",
                        border: "1px solid #DBE4F0",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                      icon={<TrophyIcon width={24} height={24} />}
                      checkedIcon={<TrophyIcon width={24} height={24} />}
                    />
                  </SCheckbox>
                </Box>
              </div>
              <div
                style={{ display: "flex", padding: "24px 0", gap: "0 32px" }}
              >
                <Input
                  inputType="date"
                  fullWidth
                  label="Дата"
                  variant="outlined"
                  name="startDate"
                  value={store.workoutStore.workoutData.startDate}
                  onChange={onChange}
                />
                <Input
                  inputType="select"
                  variant="outlined"
                  value={store.workoutStore.workoutData.type}
                  onChange={onChange}
                  label="Активность"
                  fullWidth
                  name="type"
                  disabled={store.workoutStore.workoutData.id !== undefined}
                  id="demo-simple-select"
                  items={[
                    {
                      leftContent: <RunningIcon color="#7B8A9E" />,
                      value: "Бег",
                      id: WorkoutType[WorkoutType.running],
                    },
                    {
                      leftContent: <VeloIcon color="#7B8A9E" />,
                      value: "Вело",
                      id: WorkoutType[WorkoutType.cycling],
                    },
                    {
                      leftContent: <SwimIcon color="#7B8A9E" />,
                      value: "Плавание",
                      id: WorkoutType[WorkoutType.swimming],
                    },
                    {
                      leftContent: <FitnessIcon color="#7B8A9E" />,
                      value: "Силовая",
                      id: WorkoutType[WorkoutType.powerTraining],
                    },
                  ]}
                />
              </div>
              <Input
                inputType="text"
                fullWidth
                label="Описание"
                id="outlined-textarea"
                placeholder="Введите описание тренировки"
                multiline
                name="description"
                value={workoutData.description}
                onChange={onChange}
                style={{ maxHeight: "96px", width: "592" }}
                maxRows={3}
                minRows={3}
                variant="outlined"
              />

              <TrainingData />
            </FormControl>
          </Box>
        </Box>

        <SButtonsWrapper>
          <SCancelButton
            onClick={() => store.coachStore.setWorkoutModalOpen(false)}
          >
            Отменить
          </SCancelButton>
          <SSaveButton onClick={save}>Сохранить</SSaveButton>
        </SButtonsWrapper>
      </Box>
    </Modal>
  );
};

const SCloseIcon = styled.div`
  cursor: pointer;
`

const SButtonsWrapper = styled.div`
  display: flex;
  gap: 0 24px;
  padding: 16px 24px;
  box-sizing: border-box;
  flex: 1;
  justify-content: flex-end;
`

const SButton = styled(Button)`
  font-size: 14px;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;
`

const SCancelButton = styled(SButton)`
  background-color: #EEF5FB;
  color: #1A222D;

  &:hover {
    background-color: #DBE4F0;
  }

  &:focus {
    background-color: #DBE4F0;
  }
`

const SSaveButton = styled(SButton)`
  background-color: #FF3147;
  color: #fff;

  &:hover {
    background-color: #CC2739;
  }

  &:focus {
    background-color: #CC2739;
  }
`

const SCheckbox = styled.div`
  & svg {
    color: #97A4B7;
  }
  & span.Mui-checked {
    background-color: #FFD541!important;
    border: 1px solid #FFD541;
  }
  & .Mui-checked svg {
    color: #fff;
  }
`
