import { makeStyles } from 'tss-react/mui';

export const verifyCodeStyles = makeStyles()((theme) => {
  return {
    header: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '36px',
      letterSpacing: '0.01em',
      color: '#181818',
      marginBottom: '48px',
    },
    headerSubItem: {
      fontSize: '20px',
    },
    submitWrapper: {
      color: '#fff',
    },
    wrapper: {
      display: 'flex !important',
      justifyContent: "center !important",
      marginTop: '164px',
      '& input[type="text"]:focus': {
        border: '0.75px solid #44CAAA !important',
        outline: 'none'
      }
    },
    wrapperError: {
      '& input[type="text"]': {
        border: '0.75px solid #FF3147 !important'
      },
      '& input[type="text"]:focus': {
        border: '0.75px solid #FF3147 !important'
      }
    },
    receivePromptWrapper: {
      marginTop: '17px',
    },
    verifyCodeExpiredWrapper: {
      margin: '12px',
    },
    verifyCodeExpiredText: {
      color: '#FF3147',
      textAlign: 'center',
      fontFamily: "Golos Text",
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      visibility: 'hidden',
    },
    verifyCodeExpiredTextVisible: {
      visibility: 'visible',
    },
    linkButton: {
      paddingLeft: '10px',
      color: '#FF3147',
      cursor: 'pointer',
      '&:hover': {
        color: '#CC2739'
      },
    },
    linkButtonDisabled: {
      color: '#FFC0C0'
    }
  } 
});
