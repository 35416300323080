import {ExternalLoginResult, OAuthResult, OAuthResultEvent} from "./types";
import AppStore from "../../stores/Store";
import AppApi from "../../apis/AppApi";

export default class OAuthApi {
    constructor(private api: AppApi, private store: AppStore) {
        this.api = api;
        this.store = store;
    }

    private getChallengeEndpoint = (provider: string, redirect?: string | null) =>
        redirect ?
            `${process.env.REACT_APP_BASE_URL}/auth/api/v1/external?provider=${provider}&redirect=${redirect}` :
            `${process.env.REACT_APP_BASE_URL}/auth/api/v1/external?provider=${provider}`;

    private createOAuthMessageHandler = async (event: OAuthResultEvent) : Promise<ExternalLoginResult> => {
      if (event.data?.type === "oauth" && event.data?.origin === "yna") {
        if (event.data.payload.Error) {
          return new Promise((_, reject) => reject({ needEmail: false, result: false, error: event.data.payload.Error }));
        }

        if (event.data?.payload?.AccessToken) {
          this.store.authStore.saveAuthToken(event.data.payload.AccessToken, event.data.payload.RefreshToken);
        }

        if (event.data?.payload?.VerificationToken) {
            this.store.authStore.setVerificationToken(event.data.payload.VerificationToken);
        }

        return new Promise(resolve => resolve({
          identifier: event.data.payload.Identifier,
          fullName: event.data.payload.FullName,
          accessToken: event.data.payload.AccessToken,
          refreshToken: event.data.payload.RefreshToken,
          needEmail: event.data.payload.NeedEmail,
          link: event.data.payload.Link,
          provider: event.data.payload.Provider,
          verificationToken: event.data.payload.VerificationToken,
          redirect: event.data.payload.Redirect,
          result: true,
          error: undefined
        }));
      }
      
      return new Promise((_, reject) => reject({ needEmail: false, result: false }))
    };
    
    public async getOAuthCode(provider: string, redirect?: string | null): Promise<ExternalLoginResult>
    {
        return new Promise((resolve, reject) => {
            window.addEventListener(
                'message',
                async (e) => {
                    try {
                      const result = await this.createOAuthMessageHandler(e);
                      resolve(result);
                    }
                    catch (e) {
                      reject(e);
                    }
                },
                { once: true },
            );
            window.open(
                this.getChallengeEndpoint(provider, redirect),
                undefined,
                'toolbar=no,menubar=no,directories=no,status=no,width=800,height=600',
            );
        });
    }
    
    public async linkEmail({ identity, email, loginProvider }: { identity: string, email: string, loginProvider: string }) {
        console.log("link external email");
        const response = await this.api.client.post("/auth/api/v1/external/email", {
            identity: identity,
            email: email,
            loginProvider: loginProvider,
        });

        const data = response.data;

        if (response.status === 200) {
            if (data.accessToken) {
                await this.store.authStore.saveAuthToken(
                    data.accessToken,
                    data.refreshToken
                );
            }
            
            if (data.verificationToken) {
                this.store.authStore.setVerificationToken(data.verificationToken);
            }
            return { success: true };
        } else {
            return { success: false, errors: ["ошибка"] };
        }
    }
}
