import AppApi from "./AppApi";
import AppStore from "../stores/Store";

export default class CoachApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.api = api;
    this.store = store;
  }

  async loadMyAthletes() {
    const response = await this.api.client.get(
      "/users/api/v1/athletes/mine?page=1&total=1000"
    );
    if (response.status === 200) {
      const items = response.data;
      if (this.store.authStore.currentUser) {
        items.reverse().push(this.store.authStore.currentUser);
        items.reverse();
        this.store.coachStore.mineAthletesList = items;
      }
    } else {
      //
    }
  }

  async loadAllAthletes() {
    const response = await this.api.client.get(
      "/users/api/v1/athletes/?page=1&total=1000"
    );
    if (response.status === 200) {
      this.store.coachStore.athletesList = response.data;
    } else {
      //
    }
    //return this.store.coachStore.mineAthletesList;
  }
  async addAthlete(id: string) {
    const response = await this.api.client.put(
      `/users/api/v1/athletes/${id}`,
      {}
    );
    if (response.status === 200) {
      return;
      //  = response.data;
      // this.store.saveAuthToken()
    } else {
      throw Error(response.statusText);
    }
  }
}
