import axios from "axios";
import Store from "../stores/Store";
import AuthApi from "./AuthApi";
import CoachApi from "./CoachApi";
import WorkoutApi from "./WorkoutApi";
import OAuthApi from "../components/login/ExternalAuth";
import { JwtToken } from "../stores/AuthStore";
import ProfileApi from "./ProfileApi";
import SettingsApi from "./SettingsApi";

export default class AppApi {
  client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  clientWithoutInterceptors = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  authApi: AuthApi;
  coachApi: CoachApi;
  workoutApi: WorkoutApi;
  oauthApi: OAuthApi;
  profileApi: ProfileApi;
  settingsApi: SettingsApi;
  constructor(store: Store) {
    this.loadAuthInterceptor();
    this.authApi = new AuthApi(this, store);
    this.coachApi = new CoachApi(this, store);
    this.workoutApi = new WorkoutApi(this, store);
    this.oauthApi = new OAuthApi(this, store);
    this.profileApi = new ProfileApi(this, store);
    this.settingsApi = new SettingsApi(this, store);
  }

  loadAuthInterceptor() {
    this.client.interceptors.request.use(
       (config) => {
         const access_token = localStorage.getItem(JwtToken)
         if (access_token) {
           config.headers.Authorization = 'Bearer ' + access_token;
         }

          return config;
        },
        (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (!localStorage.getItem(JwtToken)) {
          return Promise.reject(error);
        }
        
        const originalConfig = error.config;
        if (error.response) {
          if (error.response.status === 401 && !originalConfig.sent) {
            originalConfig.sent = true;
            const result = await this.authApi.refresh();
            if (!result) {
              window.location.href = "/login";
              return;
            }
            const access_token = localStorage.getItem(JwtToken);
            this.client.defaults.headers.common["Authorization"] =
              "Bearer " + access_token;
            return this.client(originalConfig);
          }

          if (error.response.status === 401 && error.response.data) {
            return Promise.reject(error.response.data);
          }
        }

        return Promise.reject(error);
      }
    );
  }
}
