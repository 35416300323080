import {Button, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {authStyles} from "../../../../styles/authStyles";
import {useAppContext} from "../../../../AppContext";
import {useFormik} from "formik";
import {useLocation, useNavigate, useParams} from "react-router-dom";

export const AddExternalEmailForm = () => {
  const { classes } = authStyles();
  const { api, store } = useAppContext();
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.has("redirect") ? params.get("redirect") : null;
  
  const formik = useFormik({
    initialValues: {
      id: id,
      email: '',
    },
    onSubmit: async (values) => {
      const result = await api.oauthApi.linkEmail({
        identity: store.authStore.getIdentifier(),
        loginProvider: store.authStore.getLoginProvider(),
        email: values.email,
      });
      if (result.success) {
        navigate('/login/verify-code', { replace: true });
      }
    }
  });

  let navigate = useNavigate();
  
  useEffect(() => {
    if (!store.authStore.getIdentifier())  {
      navigate('/login', { replace: true });
    }
  });

  return <>
    <Typography variant="h4" className={classes.header}>Добавление адреса электронной почты</Typography>
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <TextField
              name="email" className="yna" autoComplete="off" label="Email" variant="outlined"
              value={formik.values.email} fullWidth
              onChange={formik.handleChange} />
        </Grid>
      </Grid>
      <Grid container className={classes.submitWrapper}>
        <Button className="yna" type="submit" variant="contained" color="primary" fullWidth>
          Вход
        </Button>
      </Grid>
    </form>
  </>
}