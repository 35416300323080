import {Typography} from "@mui/material";
import React, {useState, useEffect} from "react";

type RetryCodeLinkButtonProps = {
    onRetryCodeHandler?: () => void;
    className?: string;
    disabledClassName?: string;
    title: string;
}

export const RetryCodeLinkButton = (props: RetryCodeLinkButtonProps) => {
    const [downTime, setDownTime] = useState(0);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setDownTime(downTime - 1);
        }, 1000);
        
        if (downTime == 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [downTime])
    
    const onRetryCodeHandler = () => {
        if (downTime <= 0) {
            props.onRetryCodeHandler?.();
            setDownTime(60);
        }
    }
    
    const getClassName = () => {
        if (downTime > 0) {
            return `${props.className} ${props.disabledClassName}`;
        }
        return props.className;
    }
    
    return <Typography onClick={onRetryCodeHandler} className={getClassName()} component="span">
        {downTime > 0  ? `${props.title} (${downTime})` : `${props.title}`}
    </Typography>
}