import {verifyCodeStyles} from "../../../../styles/verifyCodeStyles";
import {useAppContext} from "../../../../AppContext";
import {useFormik} from "formik";
import {useLocation, useNavigate, useNavigation} from "react-router-dom";
import {Button, Grid, Typography,} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from '@emotion/styled';
import ReactCodeInput from "react-code-input";
import {VerifyCodeTypes} from "../../types";
import {RetryCodeLinkButton} from "./RetryCodeLinkButton";

export type VerifyCodeFormProps = {
    type: VerifyCodeTypes,
}

export const VerifyCodeForm = (props?: VerifyCodeFormProps) => {
    const { classes } = verifyCodeStyles();
    const { api, store } = useAppContext();
    const [ verifyCodeExpired, setVerifyCodeExpired ] = useState(false);

    const { t } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const formik = useFormik({
        initialValues: {
            token: store.authStore.getVerificationToken()?.token ?? '',
            code: ""
        },
        onSubmit: async (values) => {
            setVerifyCodeExpired(false);
            const result = await api.authApi.verifyCode({
                token: values.token,
                code: values.code,
                type: props?.type
            });
            if (result.success) {
                redirect(props);
            } else {
                if (result.errors === 'verify_code_expired') {
                    setVerifyCodeExpired(true);
                }
            }
        },
    });
    
    const redirect = (props?: VerifyCodeFormProps) => {
        if (props) {
            if (props.type === VerifyCodeTypes.registration) {
                store.authStore.clearVerificationToken();
                if (params.has("redirect")) {
                    const redirectUrl = params.get("redirect");
                    navigate(redirectUrl || "/dashboard");
                } else {
                    navigate("/dashboard");
                }
                return;
            }

            if (props.type === VerifyCodeTypes.restorePassword) {
                navigate(`/login/new-password?token=${btoa(store.authStore.getVerificationToken()?.token ?? '')}`);
                return;
            }
        }
        
        navigate("/login", { replace: true });
    }
    
    const onRetryCodeHandler = async () => {
        await api.authApi.resendVerifyCode();
    }
    
    const verifyCodeExpiredClassName = () => {
        return !verifyCodeExpired ? classes.verifyCodeExpiredText : `${classes.verifyCodeExpiredText} ${classes.verifyCodeExpiredTextVisible}`;
    }

    const verifyCodeInputWrapperClassName = () => {
        return !verifyCodeExpired ? classes.wrapper : `${classes.wrapper} ${classes.wrapperError}`;
    }

    return (
        <SWrapper>
            <Typography variant="h4" className={classes.header}>
                {t("verifyCodeTitle")}
            </Typography>
            <Typography variant="h5" className={classes.headerSubItem}>
                {t("verifyCodeSubtitle")}
            </Typography>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Grid container>
                    <Grid item xs={12}>
                        <ReactCodeInput
                            type="text"
                            fields={4}
                            name="code"
                            inputMode={"tel"}
                            className={verifyCodeInputWrapperClassName()}
                            inputStyle={{
                                width: '55px',
                                height: '80px',
                                background: '#FFFFFF',
                                border: '0.75px solid #CDD9EA',
                                borderRadius: '4px',
                                fontSize: '55px',
                                textAlign: 'center',
                                marginRight: '25px'
                            }}
                            value={formik.values.code}
                            onChange={(value) => formik.setFieldValue("code", value)} />
                    </Grid>
                </Grid>
                <Grid
                    className={classes.verifyCodeExpiredWrapper}
                    item
                    display={"flex"}
                    alignContent={"center"}
                    justifyContent={"center"}>
                    <Typography className={verifyCodeExpiredClassName()} component="span">{t("verifyCodeExpired")}</Typography>
                </Grid>
                <Grid container className={classes.submitWrapper}>
                    <Button
                        className="yna"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={formik.values.code?.length !== 4}
                        fullWidth
                    >
                        {t("verifyCodeBtn")}
                    </Button>
                </Grid>
            </form>
            <Grid
                className={classes.receivePromptWrapper}
                item
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}>
                <RetryCodeLinkButton
                    onRetryCodeHandler={onRetryCodeHandler}
                    className={classes.linkButton}
                    disabledClassName={classes.linkButtonDisabled}
                    title={t("retryCodeLinkTitle")} />
            </Grid>
            <SButtonContainer>
                <SButton fullWidth
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(-1);
                    }}
                >
                    Отмена
                </SButton>
            </SButtonContainer>
        </SWrapper>
    );
};

const SWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 0;
`;
const SButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 166px;
  height: 58px;
  column-gap: 20px;
  justify-content: center;
`;
const SButton = styled(Button)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Black, #1a222d);
  border-radius: 6px;
  background: var(--G2, #eef5fb);
  text-transform: none;
`;